import { Tab, Tabs } from '@mui/material';
import { useTabs } from 'hooks';
import { TabDeposit, TabStats, TabWithdraw } from './components';

const Transaction = () => {
  const tabs = [
    { code: 'withdraw', label: 'Rút', component: <TabWithdraw /> },
    { code: 'deposit', label: 'Nạp', component: <TabDeposit /> },
    { code: 'stats', label: 'Tham gia', component: <TabStats /> },
  ];
  const [activeTab, onTabChange] = useTabs(tabs);

  return (
    <div className='flex flex-col w-full'>
      <div className='min-h-[60px] flex justify-center items-center'>
        <span className='font-bold text-xl text-[#465A7A]'>Giao dịch</span>
      </div>
      <Tabs value={activeTab} onChange={onTabChange} variant='fullWidth'>
        {tabs.map((tab) => (
          <Tab className='text-[#465A7A]' key={tab.code} label={tab.label} value={tab.code} />
        ))}
      </Tabs>

      <div className='flex-1 bg-white p-[12px]'>
        {tabs.map((tab) => (
          <div key={tab.code} hidden={tab.code !== activeTab} className='mt-[12px]'>
            {tab.component}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Transaction;
